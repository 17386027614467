<div class="container mt-xl-10 mt-2">
  <form class="col-12 col-lg-8 offset-lg-2" [formGroup]="form" (ngSubmit)="onSubmit()">
    <h1 class="text-center">Create your account</h1>
    <div class="mb-4 text-center">
      <p>Secure your account with a personal email and a strong password to protect your health information.</p>
    </div>
    <div class="mb-5 col-md-8 offset-md-2 col-12">
      <om-label-wrapper class="d-block mb-3" label="Log-in Email" [hasError]="emailError">
        <om-email-input #logInEmail formControlName="logInEmail" placeholder="Enter email"></om-email-input>
        <small class="text-black-50"> We recommend using a personal email not a work email </small>
        <small *ngIf="emailError" data-cy="error-message" class="text-danger mt-2 mb-1 d-block">
          {{ emailError }} If you've lost access to your account
          <a [omTrackLink]="trackResetPasswordClicked" href="{{ links.forgotPassword }}">reset your password</a>, or
          email <a [omTrackLink]="trackEmailAdminClicked" href="{{ links.adminEmail }}">admin@onemedical.com</a>
        </small>
      </om-label-wrapper>

      <om-label-wrapper
        class="mb-3"
        label="Create a Password"
        [hasError]="passwordError"
        [errorMessage]="passwordError"
      >
        <om-password-input #password formControlName="password" placeholder="Enter password"></om-password-input>
      </om-label-wrapper>
    </div>
    <om-submit-button
      class="col-md-8 offset-md-2 col-12 d-block"
      label="Create Account"
      [submitting]="submitting"
      [disabled]="!form.valid"
      [attr.aria-disabled]="!form.valid"
    ></om-submit-button>
  </form>
</div>
