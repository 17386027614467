import { SafeHtml } from '@angular/platform-browser';
import kebabCase from 'lodash-es/kebabCase';
import replace from 'lodash-es/replace';
import snakeCase from 'lodash-es/snakeCase';
import upperFirst from 'lodash-es/upperFirst';

import { PatientTaskOrderType, PatientTaskState } from '@app/__generated__/graphql.types';
import { PatientTasks_patient_patientTasks_nodes } from '@app/care-plan/patient-task/__generated__/PatientTasks';
import { PatientTaskNodes } from '@app/care-plan/patient-task/__generated__/patient-tasks-graphql.fragments.types';
import { MarkdownService } from '@app/core/markdown.service';
import { DocumentItem, S3Pointers } from '@app/shared/document-item';

import { Provider } from '../shared/provider';
import {
  OrderContent,
  BasicFollowUpOrderContent,
  ConsultOrderContent,
  GenericFollowUpOrderContent,
  LabOrderContent,
  ProcedureOrderContent,
  SurveyOrderContent,
  VaccineOrderContent,
  VisitFollowUpOrderContent,
  BuildContentFromTaskGraphQL,
} from './task-content.types';

export interface ContentConverter {
  convert(content: string): SafeHtml | string;
}

export class Task {
  id: number;
  state: string;
  scheduledAt: string;
  type: string;
  iconName: string;
  title: string;
  completedOn: string;

  subtitle?: string;
  phiVisibleTitle: string;
  instructions: SafeHtml;
  instructionsTitle: string;
  orderedByString: string;
  scheduledAtString: string;
  scheduledAtDate: Date;
  taskOptionsTitle: string;
  taskOptionsDescription: string;
  inactiveAtString: string;
  s3Pointers: DocumentItem[];

  declinedOn: string;
  provider?: Provider;
  contentType?: string;
  dueOnString?: string;
  content: Partial<OrderContent>;

  constructor() {}

  static fromApiV2(object: Record<string, any>, markdownService: MarkdownService): Task {
    const task = new Task();
    task.id = object.id;
    task.state = object.state;
    task.scheduledAt = object.scheduled_at;
    task.type = object.content_type;
    task.iconName = replace(object.content_type, /_/g, '-');
    task.completedOn = object.completed_on;
    task.declinedOn = object.declined_on;
    task.phiVisibleTitle = object.phi_visible_title;
    task.instructions = markdownService.convert(object.instructions || '');
    task.instructionsTitle = object.instructions_title;
    task.subtitle = object.subtitle;
    task.orderedByString = object.ordered_by_string;
    task.scheduledAtString = object.scheduled_at_string;
    task.dueOnString = object.scheduled_at_string;
    task.inactiveAtString = object.inactive_at_string;
    task.scheduledAtDate = new Date(object.scheduled_at);
    task.taskOptionsTitle = object.task_options_title;
    task.taskOptionsDescription = object.task_options_description;
    task.provider = Provider.fromApiV2(object.internal_user);
    task.content = Task.buildContent(object, markdownService);
    task.s3Pointers = (<Record<string, any>[]>(object.s3_pointers || [])).map(pointer =>
      DocumentItem.fromApiV2(pointer),
    );
    return task;
  }

  static fromGraphQL(
    object: Partial<PatientTasks_patient_patientTasks_nodes | PatientTaskNodes>,
    markdownService?: ContentConverter,
  ): Task {
    const task = new Task();

    if (!markdownService) {
      markdownService = { convert: (content: string) => content };
    }

    task.id = parseInt(object.id, 10);
    task.state = object.state;
    task.type = snakeCase(object.orderType);
    task.title = object.phiVisibleTitle;
    task.contentType = kebabCase(object.orderType);
    task.subtitle = object.notificationSubtitle;
    task.provider = object.provider ? Provider.fromGraphQL(object.provider) : null;
    task.phiVisibleTitle = object.phiVisibleTitle;
    task.completedOn = object.completedOn;
    task.declinedOn = object.declinedOn;
    task.instructions = markdownService.convert(object.instructions || '');
    task.instructionsTitle = object.instructionsTitle;
    task.dueOnString = object.dueOnString;
    task.iconName = kebabCase(object.orderType);
    task.orderedByString = object.orderedByString;
    task.scheduledAtString = object.dueOnString;
    task.scheduledAt = object.dueOnString;
    task.inactiveAtString = object.inactiveAtString;
    task.scheduledAtDate = new Date(object.dueOn);
    task.s3Pointers = (<S3Pointers[]>(object.s3Pointers || [])).map(pointer => DocumentItem.fromGraphql(pointer));

    task.content = new BuildContentFromTaskGraphQL(object, markdownService).build();

    return task;
  }

  /**
   * @deprecated Please use the instance method getter.
   */
  private static isCompletable(type: string): boolean {
    return (
      type !== 'basic_follow_up_order' &&
      type !== 'lab_order' &&
      type !== 'survey_order' &&
      type !== 'generic_follow_up_order'
    );
  }

  /**
   * @deprecated Please use the state instance method getters.
   */
  private static isCompleted(state: string): boolean {
    return state === 'completed';
  }

  /**
   * @deprecated Please use the state instance method getters.
   */
  private static isDeclined(state: string): boolean {
    return state === 'declined';
  }

  get isAssigned() {
    return this.state === PatientTaskState.Assigned;
  }

  get isCancelled() {
    return this.state === PatientTaskState.Cancelled;
  }

  get isCompleted() {
    return this.state === PatientTaskState.Completed;
  }

  get isDeclined() {
    return this.state === PatientTaskState.Declined;
  }

  get isExpired() {
    return this.state === PatientTaskState.Expired;
  }

  private static buildContent(object: Record<string, any>, markdownService: MarkdownService) {
    if (object.content_type === 'lab_order') {
      return Task.buildLabOrderContent(object.content_attributes);
    } else if (object.content_type === 'visit_follow_up_order') {
      return Task.buildVisitFollowUpOrderContent(object.content_attributes);
    } else if (object.content_type === 'basic_follow_up_order') {
      return Task.buildBasicFollowUpOrderContent(object.content_attributes);
    } else if (object.content_type === 'vaccine_order') {
      return Task.buildVaccineOrderContent(object.content_attributes, markdownService);
    } else if (object.content_type === 'survey_order') {
      return Task.buildSurveyOrderContent(object.content_attributes);
    } else if (object.content_type === 'generic_follow_up_order') {
      return Task.buildGenericFollowUpOrderContent(object.content_attributes);
    } else if (object.content_type === 'consult_order') {
      return Task.buildConsultOrderContent(object.content_attributes);
    } else if (object.content_type === 'procedure_order') {
      return Task.buildProcedureOrderContent(object.content_attributes);
    }
  }

  private static buildLabOrderContent(attributes: Record<string, any>): LabOrderContent {
    const content = new LabOrderContent();
    content.fastingRequired = attributes.fasting_needed;
    content.numberOfTests = attributes.tests_ordered.length;
    content.testsOrdered = attributes.tests_ordered;
    content.appointmentNeeded = attributes.appointment_needed;
    return content;
  }

  private static buildVisitFollowUpOrderContent(attributes: Record<string, any>): VisitFollowUpOrderContent {
    const content = new VisitFollowUpOrderContent();
    content.appointmentType = attributes.appointment_type_description.toLowerCase();
    content.appointmentTypeId = attributes.appointment_type_id;
    content.visitReason = attributes.reason_details;
    content.visitWithProvider = attributes.visit_with_provider && Provider.fromApiV2(attributes.visit_with_provider);
    return content;
  }

  private static buildBasicFollowUpOrderContent(attributes: Record<string, any>): BasicFollowUpOrderContent {
    const content = new BasicFollowUpOrderContent();
    content.problem = attributes.problem.description;
    content.feedbackOptions = ['Worse', 'Same', 'Better', 'Great'];
    content.feedback = attributes.patient_feedback ? upperFirst(attributes.patient_feedback) : null;
    return content;
  }

  private static buildVaccineOrderContent(
    attributes: Record<string, any>,
    markdownService: MarkdownService,
  ): VaccineOrderContent {
    const content = new VaccineOrderContent();
    content.isAutomated = attributes.is_automated;
    content.name = attributes.name;
    content.step = attributes.step;
    content.walkin = attributes.walkin;
    content.patientEduText = markdownService.convert(attributes.patient_edu_text);
    content.isSeries = attributes.is_series;
    content.isFluVaccine = attributes.is_flu_vaccine;
    content.appointmentTypeId = attributes.appointment_type_id;
    return content;
  }

  private static buildSurveyOrderContent(attributes: Record<string, any>): SurveyOrderContent {
    const content = new SurveyOrderContent();
    content.surveySystemName = attributes.survey?.system_name;
    return content;
  }

  private static buildGenericFollowUpOrderContent(attributes: Record<string, any>): GenericFollowUpOrderContent {
    const content = new GenericFollowUpOrderContent();
    content.question = attributes.question;
    return content;
  }

  private static buildConsultOrderContent(attributes: Record<string, any>): ConsultOrderContent {
    const content = new ConsultOrderContent();
    content.consultantDisplayName = attributes.consultant.display_name;
    content.consultantPhoneNumber = attributes.consultant.phone_number;
    content.consultantSpecialtyName = attributes.consultant.specialty_name;
    content.consultantFacilityName = attributes.consultant.facility_name;
    content.consultantAddress1 = attributes.consultant.address?.address1;
    content.consultantAddress2 = attributes.consultant.address?.address2;
    content.consultantCity = attributes.consultant.address?.city;
    content.consultantState = attributes.consultant.address?.state;
    content.consultantZip = attributes.consultant.address?.zip;
    content.consultantLatitude = attributes.consultant.address?.latitude;
    content.consultantLongitude = attributes.consultant.address?.longitude;
    return content;
  }

  private static buildProcedureOrderContent(attributes: Record<string, any>): ProcedureOrderContent {
    const content = new ProcedureOrderContent();
    content.consultantDisplayName = attributes.consultant.display_name;
    content.consultantPhoneNumber = attributes.consultant.phone_number;
    content.consultantSpecialtyName = attributes.consultant.specialty_name;
    content.consultantFacilityName = attributes.consultant.facility_name;
    content.consultantAddress1 = attributes.consultant.address?.address1;
    content.consultantAddress2 = attributes.consultant.address?.address2;
    content.consultantCity = attributes.consultant.address?.city;
    content.consultantState = attributes.consultant.address?.state;
    content.consultantZip = attributes.consultant.address?.zip;
    content.consultantLatitude = attributes.consultant.address?.latitude;
    content.consultantLongitude = attributes.consultant.address?.longitude;
    return content;
  }

  isCompletable({
    removeMemberAbilityToCompleteVaccineOrders,
  }: {
    removeMemberAbilityToCompleteVaccineOrders: boolean;
  }) {
    return (
      this.type !== snakeCase(PatientTaskOrderType.BasicFollowUpOrder) &&
      this.type !== snakeCase(PatientTaskOrderType.LabOrder) &&
      this.type !== snakeCase(PatientTaskOrderType.SurveyOrder) &&
      this.type !== snakeCase(PatientTaskOrderType.GenericFollowUpOrder) &&
      (this.type !== snakeCase(PatientTaskOrderType.VaccineOrder) || !removeMemberAbilityToCompleteVaccineOrders)
    );
  }
}

export interface TaskWithSurveyOrderContent extends Task {
  content: SurveyOrderContent;
}
